import { reqGetSearchWord } from '@/api/search'

const state = {
  wordList: [],
  Id: '',
  searchValList: JSON.parse(window.localStorage.getItem('searchValList')) || [],
}
const mutations = {
  GETSEARCHWORD(state, data) {
    state.wordList = data
  },
  CLEAR(state) {
    state.wordList = []
  },
  SAVEID(state, id) {
    state.Id = id
  },
  // 保存搜索关键字
  SAVEVAL(state, val) {
    // 保存历史搜索
    state.searchValList.unshift(val)
    state.searchValList = [...new Set(state.searchValList)]
    if (state.searchValList.length > 6) {
      state.searchValList.pop()
    }
    window.localStorage.setItem('searchValList', JSON.stringify(state.searchValList))
  },
  // 清空历史搜索
  CLEARHISTORY(state) {
    window.localStorage.removeItem('searchValList')
    state.searchValList = []
  },
}
const actions = {
  // 获取搜索关键字
  async getSearchWord({ commit }, searchWord) {
    const res = await reqGetSearchWord(searchWord)
    if (res.code == 200) {
      commit('GETSEARCHWORD', res.result)
    }
    // 清空搜索关键字数据
    if (res.code == 400) {
      commit('CLEAR')
    }
  },
  // 保存id
  saveId({ commit }, id) {
    commit('SAVEID', id)
  },
}
const getters = {}

export default {
  state,
  mutations,
  actions,
  getters,
}

// const state = {}
// const mutations = {}
// const actions = {}
// const getters = {}

// export default {
//   state,
//   mutations,
//   actions,
//   getters
// }
