import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

// 引入模块
import search from './search'
import playList from './playList'
const $vuex = new Vuex.Store({
  state: {
    userInfo: JSON.parse(window.localStorage.getItem('userinfo')),
    count: 1,
  },
  getters: {},
  mutations: {
    saveUserInfo(state, data) {
      state.userInfo = data
    },
    clearUserInfo(state) {
      state.userInfo = null
    },
    saveListCount(state) {
      if (state.count >= 2) {
        state.count = 1
      } else {
        state.count++
      }
    },
  },
  actions: {},
  modules: {
    search,
    playList,
  },
})

export default $vuex
