const state = {
  // 歌曲播放列表
  songsList: [],
  // // 单曲播放列表
  // singleList: [],
  index: 0
}
const mutations = {
  SAVESONGSLIST(state, data) {
    let newArr = []
    // 如果无版权或者无音源则不加入播放列表
    data.forEach((v) => {
      if (!v.noCopyrightRcmd) {
        // console.log(123)
        newArr.push(v)
      }
    })
    state.songsList = newArr
  },
  CLEARSONGSLIST(state, data) {
    if (data == 'clear') {
      state.songsList = []
    } else {
      state.songsList.splice(data, 1)
    }
  },
  SAVESINGLEITEM(state, data) {
    const { songsList } = state
    // 如果点击了全部播放
    if (data.length > 1) {
      // 复制一份，不能直接传值不然会数据错乱
      let newArr = []
      newArr.push(...data)
      return (state.songsList = newArr)
    }
    songsList.splice(state.index + 1, 0, data)
    var result = []
    var obj = {}

    for (var i = 0; i < songsList.length; i++) {
      // 判断obj里是否有这个id，如果有，条件为false，if语句不会执行
      if (!obj[songsList[i].id]) {
        result.push(songsList[i])
        // 给obj添加属性
        obj[songsList[i].id] = true
      }
    }
    // 重新赋值
    state.songsList = result
  },
  SAVEINDEX(state, index) {
    state.index = index
  }
}
const actions = {
  // 保存播放列表
  saveSongsList({ commit }, data) {
    commit('SAVESONGSLIST', data)
  },
  // 删除播放列表
  clearSongsList({ commit }, data) {
    commit('CLEARSONGSLIST', data)
  },
  // 保存单曲播放的歌曲
  saveSingleItem({ commit }, data) {
    commit('SAVESINGLEITEM', data)
  },
  // 保存索引
  saveIndex({ commit }, index) {
    commit('SAVEINDEX', index)
  }
}
const getters = {}

export default {
  state,
  mutations,
  actions,
  getters
}
