import axios from 'axios'

// 创建axios实例
const requests = axios.create({
  baseURL: 'https://zop.km130.com',
  timeout: 5000,
})

// 请求拦截器
// 防止走缓存，给每次请求添加时间戳
requests.interceptors.request.use(
  (config) => {
    if (config.method == 'post') {
      config.data = {
        ...config.data,
       // _timestamp: Date.parse(new Date()) / 1000,
      }
    } else if (config.method == 'get') {
      config.params = {
        ...config.params,
       // _timestamp: Date.parse(new Date()) / 1000,
      }
    }
    return config
  },
  (error) => {
    console.log(error)
    return Promise.reject(error)
  }
)

// 响应拦截器
requests.interceptors.response.use(
  // 响应成功
  (res) => {
    return res.data
  },
  (error) => {
    // 响应失败
    return error()
  }
)

export default requests
