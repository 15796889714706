import requests from './requests'

// 获取热搜列表数据
export const reqGetHotSearchList = () => requests({ url: `/search/hot/detail`, method: 'get' })

// 获取搜索建议关键字
export const reqGetSearchWord = (keywords) =>
  requests({ url: 'search/suggest', params: { keywords, type: 'mobile' } })

// 搜索歌曲
export const reqSearch = (keywords) => requests({ url: '/cloudsearch', method: 'get', params: { keywords } })

// 默认搜索关键字
export const reqSearchDefault = () => requests({ url: '/search/default' })

// 获取搜索结果
// offset : 偏移数量，用于分页
export const reqSearchResult = (keywords, type, offset) =>
  requests({ url: `/cloudsearch`, params: { keywords, type, offset } })

// 获取综合数据接口
export const reqOverAll = (keywords, type, offset) =>
  requests({ url: '/search', params: { keywords, type, offset } })
